const searchLink = document.querySelector(".search-link");
const body = document.body;
const searchForm = document.querySelector(".search-form");
const searchInp = document.querySelector(".search-form input[name='search']");
const searchRes = document.querySelector(".search__res");

let search = function (page) {
  //   let searchData = new FormData(searchForm);
  const searchData = {
    search: searchInp.value,
  };
  let param = new URLSearchParams({
    search: searchInp.value,
  });

  if (page) {
    param.append("search_page", page);
  }
  fetch("/ajax/search" + "?" + param)
    .then((res) => res.text())
    .then((res) => {
      searchRes.innerHTML = res;
    });
  //   fetch("/ajax/search", {
  //     method: "",
  //     body: JSON.stringify(searchData),
  //   });
};

searchLink.addEventListener("click", (e) => {
  e.preventDefault();
  if (body.classList.contains("search-open")) {
    body.classList.remove("search-open");
    return;
  }
  body.classList.add("search-open");
});

body.addEventListener("click", (e) => {
  if (!e.target.closest(".search-wrap")) {
    body.classList.remove("search-open");
  }
});

searchForm.addEventListener("input", () => {
  search();
});

searchForm.addEventListener("submit", (e) => {
  e.preventDefault();
  search();
});

searchRes.addEventListener("click", (e) => {
  if (e.target.closest(".pagination") && e.target.href) {
    e.preventDefault();
    const link = e.target.textContent;
    link > 1 ? search(link) : search();
  }
});
