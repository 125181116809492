//counter
document.addEventListener("click", function (e) {
    if (e.target.dataset.commerceAction) return;
    if (e.target.closest(".count-inp")) {
        const act = e.target.dataset.count;
        if (!act) return;
        let inp = e.target.closest(".count-inp").querySelector("input");
        if (act == "plus") inp.valueAsNumber += 1;
        if (act == "minus" && inp.valueAsNumber > 1) inp.valueAsNumber -= 1;
    }
});

document.addEventListener("change", function (e) {
    if (e.target.closest(".count-inp")) {
        if (!e.target.value || e.target.value < 1) e.target.value = 1;
    }
});
