import "virtual:svg-icons-register";
import NiceSelect from "nice-select2/src/js/nice-select2";
import "./component/cart";
import "./component/nav";
import "./component/search";
import "./component/form";
// import "./component/slider";
// import "./component/nav";
const selects = document.querySelectorAll("select");
function customSelect() {
    const selects = document.querySelectorAll("select");
    const initselects = document.querySelectorAll(".nice-select");
    initselects.forEach(function (el) {
        el.remove();
    });
    selects.forEach(function (select) {
        new NiceSelect(select, { searchable: true });
    });
}

selects.forEach(function (select) {
    new NiceSelect(select, { searchable: true });
});

$(document).on("after-efilter-update", function () {
    customSelect();
});
