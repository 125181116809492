import hcOffcanvasNav from "hc-offcanvas-nav";
document.addEventListener("DOMContentLoaded", function () {
    const Nav = new hcOffcanvasNav("#main-nav", {
        disableAt: 1024,
        customToggle: ".burger",
        navTitle: "Главное меню",
        levelTitles: true,
        levelTitleAsBack: true,
        closeOpenLevels: false,
    });
});
