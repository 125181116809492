const formCall = document.getElementById("callform");
const formCallCnt = document.querySelector(".call-back__cnt");

if (formCall) {
    formCall.addEventListener("submit", (e) => {
        e.preventDefault();
        fetch("/formSend", {
            method: "post",
            body: new FormData(formCall),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    formCallCnt.innerHTML = res.output;
                }
                if (res.errors) {
                    for (const prop in res.errors) {
                        // console.log(`${property}: ${object[property]}`);
                        const input = formCall.querySelector(
                            `input[name=${prop}]`
                        );
                        input.addEventListener("focus", (e) => {
                            e.target.classList.remove("error");
                        });
                        input.classList.add("error");
                    }
                }
            });
    });
}
