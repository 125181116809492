
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol  viewBox=\"0 0 33 32\" id=\"icon-cart\"><path class=\"st0\" d=\"M23.9 21H12.3c-.6 0-1-.4-1.2-.9L7.4 8.4H4.3C3.6 8.4 3 7.9 3 7.2S3.6 6 4.3 6h4c.6 0 1 .4 1.2.9l3.7 11.8h9.9l2.8-6.4H16c-.7 0-1.3-.6-1.3-1.2 0-.7.6-1.2 1.3-1.2h11.8c.4 0 .8.2 1.1.5.2.3.3.8.1 1.2l-3.8 8.8c-.3.3-.8.6-1.3.6z\" /><circle class=\"st0\" cx=\"11.4\" cy=\"25\" r=\"1.7\" /><circle class=\"st0\" cx=\"24.4\" cy=\"25\" r=\"1.7\" /></symbol><symbol  viewBox=\"0 0 21 21\" id=\"icon-search\"><path class=\"st0\" d=\"m20.5 18.9-5.2-5.2c2.5-3.3 2.3-8.1-.8-11.1C12.9 1 10.8.1 8.5.1s-4.4.9-6 2.5C1 4.2.1 6.3.1 8.6s.9 4.4 2.5 6c1.6 1.6 3.8 2.5 6 2.5 1.8 0 3.6-.6 5.1-1.7l5.2 5.2c.2.2.5.3.8.3.3 0 .6-.1.8-.3.5-.5.5-1.3 0-1.7zm-16.3-6C3 11.7 2.4 10.2 2.4 8.6c0-1.6.6-3.2 1.8-4.3 1.2-1.2 2.7-1.8 4.3-1.8 1.6 0 3.2.6 4.3 1.8 2.4 2.4 2.4 6.3 0 8.7-2.3 2.3-6.2 2.3-8.6-.1z\" /></symbol><symbol  viewBox=\"0 0 64 64\" id=\"icon-trash\"><path d=\"M28 7c-2.757 0-5 2.243-5 5v3H13a2 2 0 0 0 0 4h2.11l1.683 30.332A6.003 6.003 0 0 0 22.783 55h18.434a6.004 6.004 0 0 0 5.99-5.668L48.891 19H51a2 2 0 0 0 0-4H41v-3c0-2.757-2.243-5-5-5h-8zm0 4h8c.552 0 1 .449 1 1v3H27v-3c0-.551.448-1 1-1zm-8.887 8h25.774l-1.674 30.11A2 2 0 0 1 41.217 51H22.783a2 2 0 0 1-1.996-1.889L19.113 19zM32 23.25A1.75 1.75 0 0 0 30.25 25v20a1.75 1.75 0 1 0 3.5 0V25A1.75 1.75 0 0 0 32 23.25zm-7.357.002a1.75 1.75 0 0 0-1.688 1.809l.697 20.085a1.75 1.75 0 0 0 1.748 1.69h.063a1.751 1.751 0 0 0 1.687-1.81l-.697-20.087a1.76 1.76 0 0 0-1.81-1.687zm14.712 0a1.753 1.753 0 0 0-1.808 1.687l-.697 20.086a1.75 1.75 0 0 0 1.687 1.81c.021.002.042 0 .063 0a1.75 1.75 0 0 0 1.748-1.689l.697-20.085a1.75 1.75 0 0 0-1.69-1.809z\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}